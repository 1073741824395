@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');
@import '@fontsource/poppins';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* html {
  scroll-behavior: smooth;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#phone-input {
  flex: 1 1;
  min-width: 0;
  border: none !important;
  outline: none !important;
}

.backdropGradient {
  background: linear-gradient(90deg, rgba(1, 118, 211, .8),
      rgba(55, 55, 55, 0));
}


.customInputSize input {
  padding-left: 62px !important;
  margin: 0 !important;
  width: 100% !important;
  /* height: 57px !important; */
  padding-block: 1.4rem !important;
  /* padding-inline: 1rem !important; */
  border: none !important;
  border-radius: 12px !important;
}

.customInputSize input:focus {
  outline: none !important;
}

.customInputSize .flag-dropdown .selected-flag:hover,
.customInputSize .flag-dropdown .selected-flag.open:hover,
.customInputSize .flag-dropdown .selected-flag.open,
.customInputSize .flag-dropdown.open {
  background: #f5f5f5 !important;
}

.customInputSize .flag-dropdown {
  border: none !important;
  padding-inline: 8px !important;
  border-radius: 8px !important;
}

.conatct_us{
  background: white;
  color: #0176D3;
  border: 1px solid #0176D3
}


.otpInput {
  outline: none;
  min-width: 80px;
  min-height: 80px;
  border-radius: 5px;
  margin: 0 10px;
  font-size: 30px;
  color: black;
  background: #ffffff;
  box-shadow: 0px 0px 10px gray;
}
