.slick-slide {
    margin: 0 10px;
  }
  
  .slick-list {
    margin: 0 -10px;
  }
  
  .card-container {
    padding: 16px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
  }
  
  .card-container:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .card-icon {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .anticon {
    margin-bottom: 12px;
    color: #1890ff;
  }

  .sliderContainer {
    height: '500px'; 
  }
  
  .sliderImage {
    height: 30%;
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    border: '1px solid black'
  }
  
  .sliderContent {
    height: 300px;
    padding: 20px; /* Adjust based on your design */
    display: flex;
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center content vertically */
    align-items: flex-start; /* Align text or elements to the start */
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    /* border: 1px solid #3391DB; Optional: Solid border for definition */
    /* Additional styling for background and border radius, if needed */
    background: white; /* Consider the background color for contrast */
    border-radius: 10px; /* Rounded corners */
  }
  
  /* .sliderContent {
    height: 300px;
    padding: 20px; 
     display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: flex-start; 
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);

  } */
  
  